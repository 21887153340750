@import '../../theme/variables.scss';

.chat-page {
	.chat-messages {
		padding: 10px 0 20px;

		.message {

			.message-bubble {
				display: inline-block;
				max-width: 90%;
				padding: 8px 15px;
				margin: 10px 0;
				border-radius: 16px;
				color: #000;
				background-color: #F0F0F0;
			}

			&.system-message {
				input, textarea {
					border: 0;
					background: transparent;
					width: 100%;
				}
			}

			&.input-message {
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;

				.message-bubble {
					min-width: 90%;
				}
			}

			&.user-message {
				display: flex;
				justify-content: flex-end;

				.message-bubble {
					background-color: #F6C6D3;
					color: #000;
				}
			}

			.answers {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				width: 100%;

				.answer {
					min-width: 115px;
					margin: 10px 20px;
					padding: 8px 20px;
					font-weight: 900;
					font-size: 18px;
					text-align: center;
					border-radius: 30px;
					border: 2px solid $primary;
					background-color: #fff;
					color: #000;
					cursor: pointer;

					&.answer-call {
						padding: 0;
						a {
							display: block;
							padding: 8px 20px;
							color: $navy;
						}
					}
				}
			}
		}
	}
}