@import "./variables.scss";

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  background-color: #EC8DA8;
  color: #000;
}

h1, h2 {
  font-size: 18px;
  font-weight: 900;

  &.with-icon {
    display: flex;
    align-items: center;

    .icon {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: $secondary;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      margin-left: 10px;
      width: calc(100% - 44px);
    }
  }
}

h2 {
  font-weight: 700;
}

h3 {
  margin: 1.5em 0;
  font-size: 14px;
  font-weight: 700;
}

h4 {
  margin: 1.5em 0;
  font-size: 13px;
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

img {
  display: block;
  margin: 0;
  max-width: 100%;
}

a {
  text-decoration: none;
}

.app {
  position: relative;
  background-color: #fff;

  @include XGA {
    margin: 0 auto;
    width: 360px;
    box-shadow: 0 0 50px rgba(0,0,0,0.15);
  }

  .widget-closed {
    padding: 5px 0 5px 5px;
  }
}

.page {
  padding: 0 0 70px;

  .page-inner {
    padding: 10px 25px;
  }
}

.content {
  width: 100%;
  padding: 8px 15px 40px 15px;
}

.btn {
  display: block;
  padding: 18px;
  margin: 0 auto;
  min-width: 150px;
  width: 100%;
  text-align: center;
  border: none;
  background-color: $primary;
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  outline: none;
  border-radius: 30px;

  &.icon-btn {
    margin: 15px 0;
    font-size: 18px;
    text-align: center;
    background-color: #fff;
    border: 2px solid $primary;
    color: #000;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 58px;
    transition: .2s $QuadEaseOut background, .2s $QuadEaseOut color;

    &:hover {
      background-color: $primary;
      color: #fff;

      .icon {
        background-color: $secondary;
      }
    }

    .icon {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s $QuadEaseOut background;
    }

    span {
      line-height: 1.1;
      width: calc(100% - 50px);
    }
  }

  &.small-btn {
    margin: 25px 0;
    padding: 13px 18px;
  }

  &.center {
    text-align: center;
    margin: 30px auto;
  }

  &.call-btn {
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px;
    padding-left: 40px;
    padding-right: 40px;
    width: 220px;
    color: #fff;
    background-color: $pink;
  }

  &.small {
    font-size: 15px;
    margin: 30px auto;
    padding: 7px 20px;
    max-width: 210px;
    margin: 30px auto;
  }
}

.types-nav {
  margin-top: 40px;
}

.abuse-list {
  li {
    position: relative;
    display: block;
    padding-left: 50px;
    margin-bottom: 30px;

    .number {
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 30px;
      height: 30px;
      padding-top: 7px;
      background-color: $secondary;
      font-size: 13px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.start-page {
  .contact-btn {
    padding: 16px;
    position: relative;
    padding-left: 70px; 
    color: $navy;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);

    .icon-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 42px;
      height: 42px;
      background-color: $purple;
      border-radius: 50%;

      &.icon-map {
        img {
          width: 70%;
          margin: 15% auto;
        }
      }
    }

    &.contact-btn {
      img {
        display: block;
        width: 42px;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  .popup-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 90%;
    font-size: 14px;
    text-align: center;
    max-width: 380px;
    padding: 30px 22px 10px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px;

    p {
      font-size: 14px;

      &.larger {
        font-size: 18px;
      }
    }

    .btn {
      margin: 30px auto;
    }
  }
}

.phone-page {
  text-align: center;
}

.phone-page-2 {
  text-align: left;

  p {
    margin-top: 25px;
  }

  h3 {
    color: rgb(48, 127, 226);
    font-size: 18px;
    margin: 25px 0;
    text-align: center;
  }

  textarea {
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px;
    border-radius: 16px;
    border: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    height: 240px;
    outline: none;
    padding: 12px 16px;

    &.sent {
      background-color: #307fe2;
      color: #fff;
    }

    .btn {

    }
  }
}

.widget-item {
  position: relative;
  width: 150px;
  height: 48px;
  padding: 10px 20px 0 50px;
  color: #fff;
  font-size: 13px;
  line-height: 1.1;
  background-color: $blue;

  span {
    font-weight: 700;
  }

  .logo-widget {
    display: block;
    position: absolute;
    left: 8px;
    top: 8px;
    height: 32px;
    width: 32px;
    padding: 7px 6px 0;
    background-color: #fff;
    border-radius: 50%;

    img {
      width: 100%;
      height: auto;
      border: 0;
    }
  }

  .arrow {
    display: block;
    position: absolute;
    right: 9px;
    top: 13px;
    width: 14px;
  
    img {
      width: 100%;
      height: auto;
      border: 0;
    }
  }
}

.close-widget {
  position: fixed;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  opacity: 0.8;
  background-image: url(../../assets/icons/exit.svg);
  background-size: 20px;
  background-position: center;
  background-color: #fff;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}