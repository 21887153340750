@import "../../theme/variables.scss";

.LangSelect {
  z-index: 5;
  position: absolute;
  top: 30px;
  right: 20px;

  .lang-toggle {
    font-size: 12px;
    color: #000;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5px 22px 5px 9px;
    cursor: pointer;
    background-color: #fff;
    width: 80px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 36px;

    &.active {
      box-shadow: -44px 4px 52px 0px rgba(0, 0, 0, 0.06); 
    }
  }

  .nav-langs {
    position: absolute;
    top: 40px;
    right: 0;
    display: none;
    width: 80px;

    &.active {
      display: block;
      box-shadow: -44px 4px 52px 0px rgba(0, 0, 0, 0.06);
    }

    a {
      display: block;
      font-size: 12px;
      padding: 12px 22px 12px 9px;
      color: #000;
      font-weight: 900;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;

      &:first-child {
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
      }

      &:last-child {
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
      }

      &:hover {
        background-color: $primary;
      }
      
    }
  }
}