@import "../../theme/variables.scss";


.welcome-page {
	height: 100%;
	background-image: url(../../../assets/bg-welcome.jpg);
	background-size: cover;
	background-position: center;

	.start-btn {
		position: absolute;
		bottom: 40px;
		left: 5%;
		width: 90%;
		color: #fff;
	}

	.logo {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 300px;
		height: 236px;
		margin-left: -150px;
		margin-top: -118px;
		border: 2px solid #fff;
		border-radius: 65px 0;

		img {
			display: block;
			height: 53px;
			width: auto;
			margin: 91px auto 0;
		}
	}
}