.phone-page {
  .description {
    font-size: 18px;
    font-weight: 900;
    margin: 2em 0;
    text-align: left;
  }


  .phone {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    img {
      width: 50px;
      height: auto;
    }

    span {
      font-size: 40px;
      font-weight: 900;
    }
  }
}