@import "../../theme/variables.scss";

.header {
	position: relative;
	height: 92px;
	padding: 0;
	color: #000;
	background-color: rgba(229, 0, 75, 0.9);
	padding: 1px 20px;

	&.hide {
		display: none;
	}
	
	.logo {
		margin: 24px 0 0 0;
		width: 189px;

		img {
			display: block;
			height: auto;
			width: 100%;
		}
	}
}