@import "../../theme/variables.scss";

.footer-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 54px;
	width: 100%;
	background-color: #fff;
	color: $primary;
	box-shadow: -44px 4px 52px 0px rgba(0, 0, 0, 0.25);

	&.hide {
		display: none;
	}

	@include XGA {
		width: 360px;
		left: 50%;
		bottom: 0;
		margin-left: -180px;
	}

	.nav {
		height: 100%;
		width: 100%;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;

			.home {
				position: absolute;
				top: -24px;
				left: 50%;
				margin-left: -31px;
				width: 62px;
				height: 62px;
				background-color: $primary;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;

				@include XGA {
					&:hover {
						opacity: 1;
						background-color: $secondary;
					}
				} 
			}

			a {
				display: flex;
				align-items: center;
				height: 100%;
				color: $primary;
				font-size: 10px;
				font-weight: 800;
				text-align: center;
				-webkit-tap-highlight-color: rgba(232, 47, 137, 0.1);

				span {
					margin: 0 5px;
				}
				
				@include XGA {
					&:hover {
					color: $secondary;
					}
				}

				img {
					display: block;
					height: 30px;
					width :auto;
					margin: 0 auto 0px;
				}
			}
	}
}